import { RefObject, useEffect, useMemo, useState } from "react";

export const breakpoints = {
  mobile: 428,
  mobileLandscape: 480,
  tablet: 768,
  midTablet: 991,
  tabletLandscape: 1040,
  desktop: 1200,
  desktopLarge: 1500,
  desktopWide: 1920,
  smallMediaBreakpoint: 576,
  mediumMediaBreakpoint: 768,
};

export const useWindowInnerWidth = () => {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setInnerWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return { innerWidth };
};

export const useDevice = () => {
  const { innerWidth } = useWindowInnerWidth();
  const { smallMediaBreakpoint, mediumMediaBreakpoint } = breakpoints;
  return {
    isSmallMedia: innerWidth < smallMediaBreakpoint,
    isMediumMedia: innerWidth < mediumMediaBreakpoint,
  };
};

export function useIsVisibleOnScreen(ref: RefObject<HTMLElement>) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(
    () => new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting)),
    []
  );

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => observer.disconnect();
  }, [ref, observer]);

  return isIntersecting;
}
