import React, { useRef } from "react";

import { Slider, SliderProps } from "..";
import { useIsVisibleOnScreen } from "../../../helpers/window";

export const TravelDistanceSlider = (
  props: Omit<SliderProps, "label" | "range" | "testId" | "tooltip"> & {
    tooltip: Omit<SliderProps["tooltip"], "open" | "formatter">;
  }
) => {
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useIsVisibleOnScreen(ref);

  return (
    <div ref={ref}>
      {/*  @ts-expect-error -- antd typed their slider props so that value cant be dynamically passed in */}
      <Slider
        {...props}
        label="Travel distance"
        tooltip={{
          open: isVisible,
          formatter: (v) => `${v} miles`,
          ...props.tooltip,
        }}
        testId="travelDistanceSlider"
      />
    </div>
  );
};
