export const passwordRequirements = [
  {
    label: "At least 8 characters",
    regex: /^[><?@+'`~^%&*[\]{}.!#|\\"$';,:;=/(),\-\w]{8,}$/,
  },
  {
    label: "1 uppercase character",
    regex: /^(?=.*[A-Z])/,
  },
  {
    label: "1 lowercase character",
    regex: /^(?=.*[a-z])/,
  },
  {
    label: "1 number",
    regex: /^(?=.*[0-9])/,
  },
];
