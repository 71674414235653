import React from "react";

import { AntInputProps, Input } from "..";
import { Modify } from "../../../helpers/types";

export const ZipcodeInput = (
  props: Modify<AntInputProps, { onChange?: (zipcode: string) => void }>
) => {
  return (
    <Input
      label="ZIP code"
      inputMode="numeric"
      maxLength={5}
      name="zipcode"
      {...props}
      onChange={(e) => props.onChange?.(e.target.value.replace(/\D/g, ""))}
    />
  );
};
