import React, { FC, useState } from "react";

import { Heading } from "../../Heading";
import { Popover } from "../../Popover";
import { passwordRequirements } from "../../../helpers/form/validation";
import { Icon } from "../../Icon";
import { SmallText, Text } from "../../Text";
import { greenBase, redBase } from "../../../styles/constants";
import { useDevice } from "../../../helpers/window";
import { Input } from "..";

import { PasswordContainer } from "./styles";

export const VISIBILITY_ICON_TEST_ID = "visibility-icon";

export const PasswordInput: FC<{
  password?: string;
  onChange?: (value: string) => void;
  error?: string;
}> = ({ password, onChange, error }) => {
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPasword] = useState(false);
  const { isSmallMedia } = useDevice();

  const size = isSmallMedia ? "s" : "l";
  return (
    <Popover
      open={open}
      placement="topRight"
      content={
        <>
          <Heading level={6}>Password must include</Heading>
          {passwordRequirements.map((requirement) => {
            const valid = password && requirement.regex.test(password);
            return (
              <PasswordContainer key={requirement.label}>
                {valid ? (
                  <Icon name="Check" color={greenBase} size={size} />
                ) : (
                  <Icon name="Close" color={redBase} size={size} />
                )}
                {isSmallMedia ? (
                  <SmallText>{requirement.label}</SmallText>
                ) : (
                  <Text>{requirement.label}</Text>
                )}
              </PasswordContainer>
            );
          })}
        </>
      }
    >
      <Input
        className="ph-no-capture"
        label="Password"
        value={password}
        onChange={(e) => {
          if (onChange) {
            onChange(e.target.value);
          }
        }}
        error={error}
        name="password"
        type={showPassword ? "text" : "password"}
        onFocus={() => setOpen(true)}
        onBlur={() => setOpen(false)}
        suffix={
          <Icon
            testId={VISIBILITY_ICON_TEST_ID}
            name={showPassword ? "VisibilityOff" : "Visibility"}
            onClick={() => setShowPasword(!showPassword)}
          />
        }
      />
    </Popover>
  );
};
